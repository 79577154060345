'use strict'

const _ = require('lodash')

/**
 * @exports utils/dataFixer/plugins/characterSetsFixer
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson) {
        if (pageJson.structure && pageJson.structure.type === 'Document') {
            if (_.isEmpty(pageJson.data.document_data.masterPage.characterSets)) {
                pageJson.data.document_data.masterPage.characterSets = ['latin']
            }
        }
        return pageJson
    }
}
