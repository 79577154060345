'use strict'

const _ = require('lodash')

function retrieveIds(menuOrMenuItem) {
    return _.reduce(menuOrMenuItem.items, (ids, item) => {
        ids.push(item.refId)
        if (item.items && item.items.length) {
            return ids.concat(retrieveIds(item))
        }
        return ids
    }, [])
}

function retrieveAllMenuIds(docData) {
    return retrieveIds(docData.MAIN_MENU)
}

function removeTopLevelMenuItem(mainMenu, topLevelIndex) {
    const subMenu = mainMenu.items[topLevelIndex]
    if (subMenu.items && subMenu.items.length > 0) {
        _.forEach(subMenu.items, (menuItem, subItemIndex) => { //push the subitems to the top level array before removal
            mainMenu.items.push(menuItem)
            delete subMenu.items[subItemIndex] //delete makes that array item = undefined, does not actually change the array length
        })
    }
    subMenu.items = [] //I prefer deletion of the items + set to empty array over splice an unknown amount of times
    mainMenu.items.splice(topLevelIndex, 1)
}

function findSubMenuItem(mainMenu, id) {
    const result = {}
    //for each item
    _.find(mainMenu.items, (menuItem, topIndex) => {
        result.topIndex = topIndex
        return _.find(menuItem.items, (subItem, subIndex) => { //check all of it's subitems
            if (subItem.refId === id) {
                result.subIndex = subIndex
                return true
            }
        })
    })

    return result
}
function removeMenuItem(mainMenu, id) {
    const topLevelIndex = _.findIndex(mainMenu.items, {refId: id})
    if (topLevelIndex >= 0) { //case: id to remove is a top level menu item
        removeTopLevelMenuItem(mainMenu, topLevelIndex)
    } else { //case: id to remove is a subItem
        const location = findSubMenuItem(mainMenu, id)
        mainMenu.items[location.topIndex].items.splice(location.subIndex, 1)
    }
}

function removeAllBlankMenuItems(docData, menuIds) {
    const mainMenu = docData.MAIN_MENU
    _.forEach(menuIds, id => {
        if (!id) {
            removeMenuItem(mainMenu, id)
        }
    })

    _.pull(menuIds, '', undefined)
}
function removeMenuItemsForNonexistingPages(docData, menuIds, pageIdsArray) {
    _.forEach(menuIds, id => {
        if (!_.includes(pageIdsArray, id.slice(1))) { //id is in #csdf format
            removeMenuItem(docData.MAIN_MENU, id)
        }
    })
}
function addMissingPageIdsToMenu(docData, menuIds, pageIdsArray) {
    const mainMenu = docData.MAIN_MENU
    const emptyMainMenu = mainMenu.items.length === 0
    const hasCsmData = !!docData.CUSTOM_MAIN_MENU

    if (hasCsmData) {
        if (!emptyMainMenu) {
            delete docData.CUSTOM_MAIN_MENU
            delete docData.CUSTOM_MENUS
        } else {
            return
        }
    }
    _.forEach(pageIdsArray, id => {
        if (!_.includes(menuIds, `#${id}`)) {
            //id from pages list is not in the main menu, meaning it doesn't exist
            menuIds.push(`#${id}`) //add it to our array
            const newMenuItem = {items: [], refId: `#${id}`}
            mainMenu.items.push(newMenuItem)
        }
    })
}

function stripHash(id) {
    return id.replace('#', '')
}

function validateSiteMainPage(siteStructure, pageIdsArray) {
    if (siteStructure && siteStructure.mainPage && !_.includes(pageIdsArray, stripHash(siteStructure.mainPage))) {
        siteStructure.mainPage = `#${pageIdsArray[0]}`
    }
}

function hasReferenceToNonexistingData(docData, dataId) {
    return !docData[stripHash(dataId)]
}

function removeCustomSiteMenuIfNotLegal(docData) {
    const customMenuItems = _.get(docData, ['CUSTOM_MAIN_MENU', 'items'])
    if (customMenuItems && _.some(customMenuItems, _.partial(hasReferenceToNonexistingData, docData))) {
        delete docData.CUSTOM_MAIN_MENU
        delete docData.CUSTOM_MENUS
    }
}

function isJsonForMasterPage(pageJson) {
    return _.get(pageJson, 'structure.type') === 'Document'
}

function fixMenusOnMasterPage(docData, pageIdsArray) {
    const menuIds = retrieveAllMenuIds(docData)
    removeCustomSiteMenuIfNotLegal(docData)
    removeAllBlankMenuItems(docData, menuIds)
    removeMenuItemsForNonexistingPages(docData, menuIds, pageIdsArray)
    addMissingPageIdsToMenu(docData, menuIds, pageIdsArray)
    const siteStructure = docData.masterPage
    validateSiteMainPage(siteStructure, pageIdsArray)
}
/**
 * @export utils/dataFixer/plugins/menuFixer
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson, pageIdsArray) {
        const docData = pageJson.data.document_data
        if (isJsonForMasterPage(pageJson)) {
            docData.MAIN_MENU = docData.MAIN_MENU || {items: [], type: 'Menu', id: 'MAIN_MENU', metaData: {}}
            if (pageIdsArray) {
                fixMenusOnMasterPage(docData, pageIdsArray)
            }
        }
    }
}
