'use strict'

const _ = require('lodash')

const actionsToFix = ['screenIn']

function shouldFix(behaviorsObj) {
    return _.includes(actionsToFix, behaviorsObj.action) && !_.isUndefined(behaviorsObj.delay) && _.isUndefined(_.get(behaviorsObj, ['params', 'doubleDelayFixed']))
}

function fixDelayIfAnimation(behavior) {
    if (shouldFix(behavior)) {
        _.merge(behavior, {
            delay: parseFloat(behavior.delay) * 2,
            params: {doubleDelayFixed: true}
        })
    }
    return behavior
}

/**
 * Part of fix for https://jira.wixpress.com/browse/WEED-11138
 * If behavior is animation that has the wrong delay value, multiply it by 2 and add a 'doubleDelayFixed' flag
 * TODO: convert old behaviors to real data structure and on the way kill the flag
 * @param {*} behaviors_data
 */
function fixAnimationDelay(behaviors_data) {
    _.forEach(behaviors_data, behavior => {
        if (_.isString(behavior.items)) {
            const items = JSON.parse(behavior.items)
            const fixed = _.map(items, fixDelayIfAnimation)
            behavior.items = JSON.stringify(fixed)
        }
    })
}

/**
 * @exports utils/dataFixer/plugins/animationDelayFixer
 * @type {{exec: function}}
 */
module.exports = {
    exec(pageJson) {
        const {behaviors_data} = pageJson.data
        if (_.isEmpty(behaviors_data)) {
            return
        }
        fixAnimationDelay(behaviors_data)
    }
}
