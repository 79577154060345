'use strict'

const _ = require('lodash')

function degreesToRadians(angleInDegrees) {
    return angleInDegrees * Math.PI / 180
}

function getBoundingHeight(layout) {
    if (!layout.rotationInDegrees) {
        return layout.height
    }
    const currentAngleInRadians = degreesToRadians(layout.rotationInDegrees)
    // @ts-ignore
    return parseInt(Math.abs(layout.width * Math.sin(currentAngleInRadians)) + Math.abs(layout.height * Math.cos(currentAngleInRadians)), 10)
}

function getBoundingY(layout) {
    if (!layout.rotationInDegrees) {
        return layout.y
    }
    const boundingHeight = getBoundingHeight(layout)
    // @ts-ignore
    return parseInt(layout.y - (boundingHeight - layout.height) / 2, 10)
}

function getLowestComp(comps) {
    return _.maxBy(comps, comp => getBoundingY(comp.layout))
}

function removeInvalidAnchors(parts) {
    const {pagesContainer} = parts
    const {header} = parts
    if (pagesContainer && header) {
        _.remove(pagesContainer.layout.anchors, {targetComponent: 'SITE_HEADER', type: 'TOP_TOP'})
        _.remove(header.layout.anchors, {targetComponent: 'PAGES_CONTAINER', type: 'TOP_TOP'})
    }
}

function addMissingAnchors(components, parts) {
    const {pagesContainer} = parts
    const {header} = parts
    const {footer} = parts

    const sitePages = pagesContainer && pagesContainer.components && _.find(pagesContainer.components, {id: 'SITE_PAGES'})
    if (sitePages && sitePages.layout && (!sitePages.layout.anchors || sitePages.layout.anchors.length === 0)) {
        sitePages.layout.anchors = [
            {
                distance: 0,
                locked: true,
                originalValue: 0,
                targetComponent: 'PAGES_CONTAINER',
                type: 'BOTTOM_PARENT'
            }
        ]
    }
    if (pagesContainer && pagesContainer.layout && (!pagesContainer.layout.anchors || pagesContainer.layout.anchors.length === 0) &&
        footer && footer.layout && !footer.layout.fixedPosition) {
        pagesContainer.layout.anchors = [
            {
                distance: 10,
                locked: true,
                originalValue: 0,
                targetComponent: 'SITE_FOOTER',
                type: 'BOTTOM_TOP'
            }
        ]
    }

    if (header) {
        const hasBottomParentAnchorToSiteStructure = _.find(components, component => _.find(component.layout.anchors, {type: 'BOTTOM_PARENT', targetComponent: 'masterPage'}))
        if (!hasBottomParentAnchorToSiteStructure) {
            const lowestComp = getLowestComp(components)
            if (!lowestComp.layout.anchors) {
                return
            }
            lowestComp.layout.anchors.push({
                distance: 0,
                locked: true,
                originalValue: getBoundingY(lowestComp.layout) + getBoundingHeight(lowestComp.layout) + (header ? getBoundingHeight(header.layout) : 0),
                targetComponent: 'masterPage',
                type: 'BOTTOM_PARENT'
            })
        }
    }
}

function childrenAnchorsDeleted(childrenArr) {
    return !(childrenArr.length && childrenArr[0].layout && childrenArr[0].layout.anchors)
}

function findParts(components) {
    // Optimization: single _.forEach instead of three _.find
    const parts = {}
    _.forEach(components, component => {
        switch (component.id) {
            case 'SITE_HEADER':
                parts.header = component
                return !(parts.footer && parts.pagesContainer)
            case 'SITE_FOOTER':
                parts.footer = component
                return !(parts.header && parts.pagesContainer)
            case 'PAGES_CONTAINER':
                parts.pagesContainer = component
                return !(parts.header && parts.footer)
            default:
                return true // continue
        }
    })
    return parts
}

function fixAnchors(components) {
    if (components && !_.isEmpty(components) && !childrenAnchorsDeleted(components)) {
        const parts = findParts(components)
        removeInvalidAnchors(parts)
        addMissingAnchors(components, parts)
    }
}

/**
 * @exports utils/dataFixer/plugins/addMissingAnchorsOfMasterPage
 * @type {{exec: function, test: function}}
 */
module.exports = {
    exec(pageJson) {
        const structureData = pageJson.structure
        if (!structureData || structureData.type !== 'Document') {
            return
        }
        fixAnchors(structureData.children)
        fixAnchors(structureData.mobileComponents)
    },

    test: {
        getBoundingHeight,
        getBoundingY,
        getLowestComp
    }
}
