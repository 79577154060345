'use strict'

const _ = require('lodash')

module.exports = {
    /**
     * Converts page props to have { desktop: ..., mobile: ...} at the top level
     * @param pageJson
     */
    exec(pageJson) {
        const props = _.get(pageJson, ['data', 'component_properties', pageJson.structure.id])
        const popupProps = props && props.popup
        if (popupProps) {
            _.forEach(['desktop', 'mobile'], mode => {
                const modeValue = props[mode]
                const popupModeValue = props.popup[mode]
                if (modeValue || popupModeValue) {
                    // @ts-ignore
                    _.set(props, [mode, ['popup']], _.assign(modeValue || {}, popupModeValue || {}))
                }
            })
            delete props.popup
        }
    }
}
