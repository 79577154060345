'use strict'

const _ = require('lodash')
const {CompsMigrationHelper} = require('../helpers/CompsMigrationHelper')
const {getOverrides} = require('wix-ui-santa/overrides')
const themeDataUtils = require('../helpers/themeDataUtils')

const changeComponent = (originalCompType, newCompType, skinOverride) =>
    (clientSpecMap, page, cache, mobileView, comp) => {
        const shouldMigrateComp = comp.componentType === originalCompType

        if (shouldMigrateComp) {
            comp.componentType = newCompType
            if (skinOverride) {
                comp.skin = skinOverride
            }
        }
    }

const migrateThemes = (styleData, {newComponentSkin, overrideViewerType, newComponentViewerType}) => {
    if (styleData.componentClassName === overrideViewerType) {
        styleData.componentClassName = newComponentViewerType
        styleData.skin = newComponentSkin
    }
}

/**
 * @exports utils/dataFixer/plugins/migrateWidgetsToControllers
 * @type {{exec: function, WUSOverrides: *}}
 */
module.exports = {
    WUSOverrides: getOverrides(),
    exec(pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) {
        if (magicObject.isViewerMode) {
            const themesData = pageJson.data && pageJson.data.theme_data
            const MIGRATION_MAP = {}
            Object.values(this.WUSOverrides).forEach(override => {
                if (magicObject.isExperimentOpen(override.viewerExperimentKey)) {
                    MIGRATION_MAP[override.overrideViewerType] = _.partial(changeComponent(override.overrideViewerType, override.newComponentViewerType, override.newComponentSkin), magicObject.clientSpecMap)

                    if (!_.isEmpty(themesData)) {
                        themeDataUtils.forEachStyle(themesData, themeData => migrateThemes(themeData, override))
                    }
                }
            })
            if (Object.values(MIGRATION_MAP).length) {
                const {uniqueIdGenerator} = magicObject.dataFixerUtils
                const migrationHelper = new CompsMigrationHelper(MIGRATION_MAP, uniqueIdGenerator)
                migrationHelper.migratePage(pageJson)
            }
        }
    }
}
