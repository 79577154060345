'use strict'

const _ = require('lodash')
const {createHelperAndMigratePage} = require('../helpers/CompsMigrationHelper')

function getEmptyBackground(uniqueIdGenerator) {
    return {
        type: 'MediaContainerDesignData',
        id: uniqueIdGenerator.getUniqueId('dataItem', '-'),
        background: {
            id: uniqueIdGenerator.getUniqueId('dataItem', '-'),
            type: 'BackgroundMedia',
            mediaRef: null,
            alignType: 'center',
            fittingType: 'fill',
            colorOpacity: 0,
            colorOverlay: '',
            colorOverlayOpacity: 1,
            imageOverlay: '',
            scrollType: 'none',
            color: '#FFFFFF'
        }
    }
}

function getDefaultColumnsContainerProperties() {
    return {
        columnsMargin: 0,
        frameMargin: 0,
        fullWidth: true,
        rowMargin: 0,
        siteMargin: 0,
        type: 'StripColumnsContainerProperties'
    }
}

function getDefaultColumnProperties() {
    return {
        type: 'ColumnProperties',
        alignment: 50
    }
}

function createColumnFromStrip(comp, cache, uniqueIdGenerator) {
    return {
        id: _.get(cache, [comp.id, 'mediaId'], uniqueIdGenerator.getUniqueId('media')),
        type: 'Container',
        layout: {
            width: comp.layout.width,
            height: comp.layout.height,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            anchors: [
                {
                    distance: 0,
                    topToTop: 0,
                    originalValue: 0,
                    type: 'BOTTOM_PARENT',
                    locked: true,
                    targetComponent: comp.id
                }
            ],
            fixedPosition: false
        },
        components: comp.components,
        componentType: 'wysiwyg.viewer.components.Column',
        styleId: 'strc1'
    }
}

function migrateAnchors(comp, column) {
    _.forEach(comp.components, childComp => {
        const anchors = _.get(childComp, ['layout', 'anchors'], [])
        _.forEach(anchors, anchor => { //eslint-disable-line lodash/prefer-filter
            if (anchor.type === 'BOTTOM_PARENT') {
                anchor.targetComponent = column.id
            }
        })
    })
}

function migrateBehaviors(comp, column) {
    if (comp.behaviors) {
        column.behaviors = _.clone(comp.behaviors)
        delete comp.behaviors
    }
    if (comp.behaviorQuery) {
        column.behaviorQuery = comp.behaviorQuery
        delete comp.behaviorQuery
    }
}

function migrateStrip(pageJson, cache, mobileView, comp, uniqueIdGenerator) {
    comp.componentType = 'wysiwyg.viewer.components.StripColumnsContainer'
    delete comp.skin
    comp.styleId = 'strc1'

    this.setComponentProperties(pageJson, comp, getDefaultColumnsContainerProperties(), cache)

    const column = createColumnFromStrip(comp, cache, uniqueIdGenerator)

    migrateAnchors(comp, column)
    migrateBehaviors(comp, column)

    _.set(cache, [comp.id, 'mediaId'], column.id)

    this.setComponentProperties(pageJson, column, getDefaultColumnProperties(), cache)

    column.designQuery = comp.designQuery
    delete comp.designQuery

    if (!mobileView) {
        comp.layout.width = 980
        const designDataWithEmptyBackground = getEmptyBackground(uniqueIdGenerator)
        this.setComponentDesignData(pageJson, comp, designDataWithEmptyBackground, cache)
    } else {
        comp.layout.width = 320
        comp.designQuery = _.get(cache, [comp.id, 'designQuery'])
        this.deleteComponentData(pageJson, comp)
    }
    delete comp.dataQuery

    comp.components = [column]
}

const MIGRATION_MAP = {
    'wysiwyg.viewer.components.StripContainer': migrateStrip
}

/**
 * @exports utils/dataFixer/plugins/migrateStripToColumnsContainer
 * @type {{exec: function}}
 */

module.exports = {
    exec: createHelperAndMigratePage(MIGRATION_MAP)
}
