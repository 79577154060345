'use strict'

const _ = require('lodash')

function CompsMigrationHelper(compsMigrationMap, generator) {
    this.migrationMap = compsMigrationMap
    this.uniqueIdGenerator = generator
}

CompsMigrationHelper.prototype.getComponentData = function (pageJson, comp) {
    return comp.dataQuery && pageJson.data.document_data[comp.dataQuery.replace('#', '')]
}

CompsMigrationHelper.prototype.setComponentData = function (pageJson, comp, dataItem, cache) {
    let newId = comp.dataQuery || _.get(cache, [comp.id, 'dataQuery']) ||
        this.uniqueIdGenerator.getUniqueId('dataItem', '-')
    newId = newId.replace('#', '')
    comp.dataQuery = `#${newId}`
    dataItem.id = newId
    pageJson.data.document_data[newId] = dataItem
    _.set(cache, [comp.id, 'dataQuery'], newId)
}

CompsMigrationHelper.prototype.deleteComponentData = function (pageJson, comp) {
    const dataQuery = comp.dataQuery && comp.dataQuery.replace('#', '')
    if (dataQuery && pageJson.data.document_data[dataQuery]) {
        delete pageJson.data.document_data[dataQuery]
    }
}


CompsMigrationHelper.prototype.getComponentDesignData = function (pageJson, comp) {
    return comp.designQuery && pageJson.data.design_data[comp.designQuery.replace('#', '')]
}

CompsMigrationHelper.prototype.deleteComponentDesignData = function (pageJson, comp) {
    const designQuery = comp.designQuery && comp.designQuery.replace('#', '')
    if (designQuery && pageJson.data.design_data[designQuery]) {
        delete pageJson.data.design_data[designQuery]
    }
}


CompsMigrationHelper.prototype.getComponentProperties = function (pageJson, comp) {
    return comp.propertyQuery && pageJson.data.component_properties[comp.propertyQuery.replace('#', '')]
}
CompsMigrationHelper.prototype.deleteComponentProperties = function (pageJson, comp) {
    const propertyQuery = comp.propertyQuery && comp.propertyQuery.replace('#', '')
    if (propertyQuery && pageJson.data.component_properties[propertyQuery]) {
        delete pageJson.data.component_properties[propertyQuery]
    }
    if (comp.propertyQuery) {
        delete comp.propertyQuery
    }
}

CompsMigrationHelper.prototype.setComponentDesignData = function (pageJson, comp, designDataItem, cache) {
    let newId = comp.designQuery || _.get(cache, [comp.id, 'designQuery']) ||
        this.uniqueIdGenerator.getUniqueId('dataItem', '-')
    newId = newId.replace('#', '')
    comp.designQuery = `#${newId}`
    designDataItem.id = newId
    if (_.isObject(designDataItem.background)) {
        const bg = _.clone(designDataItem.background)
        bg.id = (bg.id || this.uniqueIdGenerator.getUniqueId('dataItem', '-')).replace('#', '')
        designDataItem.background = `#${bg.id}`
        pageJson.data.design_data[bg.id] = bg
    }
    pageJson.data.design_data[newId] = designDataItem
    _.set(cache, [comp.id, 'designQuery'], comp.designQuery)
}

CompsMigrationHelper.prototype.setComponentProperties = function (pageJson, comp, properties, cache) {
    let newId = comp.propertyQuery || _.get(cache, [comp.id, 'propertyQuery']) ||
      this.uniqueIdGenerator.getUniqueId('propItem', '-')
    newId = newId.replace('#', '')
    comp.propertyQuery = newId
    properties.id = newId
    pageJson.data.component_properties[newId] = properties
    _.set(cache, [comp.id, 'propertyQuery'], newId)
}

CompsMigrationHelper.prototype.setComponentStyle = function (pageJson, comp, style, cache) {
    const newId = comp.styleId || _.get(cache, [comp.id, 'styleId']) ||
        this.uniqueIdGenerator.getUniqueId('style', '-')
    comp.styleId = newId
    style.id = newId
    pageJson.data.theme_data[newId] = style
    _.set(cache, [comp.id, 'styleId'], newId)
}

CompsMigrationHelper.prototype.migrateComps = function (pageJson, cache, mobileView, comps) {
    if (!comps) {
        return
    }

    _.forEach(comps.slice(), comp => {
        if (this.migrationMap[comp.componentType]) {
            this.migrationMap[comp.componentType].call(this, pageJson, cache, mobileView, comp, this.uniqueIdGenerator, comps)
        }
        this.migrateComps(pageJson, cache, mobileView, comp.components)
    })
}

CompsMigrationHelper.prototype.migratePage = function (pageJson) {
    const structureData = pageJson.structure
    if (structureData) {
        const cache = {}

        const desktopComps = structureData.components || structureData.children
        const mobileComps = structureData.mobileComponents

        if (desktopComps) {
            this.migrateComps(pageJson, cache, false, desktopComps)
        }
        if (mobileComps) {
            this.migrateComps(pageJson, cache, true, mobileComps)
        }
    }
}

function createHelperAndMigratePage(migrationMap, experiment) {
    return (pageJson, pageIdsArray, requestModel, currentUrl, urlFormatModel, isViewerMode, rendererModel, magicObject) => {
        if (experiment && !magicObject.isExperimentOpen(experiment)) {
            return
        }
        const {uniqueIdGenerator} = magicObject.dataFixerUtils
        const migrationHelper = new CompsMigrationHelper(migrationMap, uniqueIdGenerator)
        migrationHelper.migratePage(pageJson)
    }
}

module.exports = {
    CompsMigrationHelper,
    createHelperAndMigratePage
}
